import axios from "axios";
import AppConfig from "AppConfig";
import AuthService from "../services/AuthService";

const apiClient = axios.create({
  baseURL: AppConfig.externalServices.baseURL,
});

let _isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const authService = new AuthService(apiClient);
    if (!error.response) {
      return Promise.reject(error);
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (_isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest._queued = true;
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return apiClient.request(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err || error);
          });
      }

      originalRequest._retry = true;
      _isRefreshing = true;

      return new Promise((resolve, reject) => {
        authService
          .refreshUserToken()
          .then((token) => {
            setAuthentication(token);
            originalRequest.headers["Authorization"] = "Bearer " + token;
            processQueue(null, token);
            resolve(apiClient.request(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err || error);
          })
          .finally(() => (_isRefreshing = false));
      });
    } else {
      return Promise.reject(error);
    }
  }
);

let authInterceptor = null;
let entitiesInterceptor = null;

export const removeAuthentication = () => {
  if (authInterceptor !== null) {
    apiClient.interceptors.request.eject(authInterceptor);
    authInterceptor = null;
  }
};

export const setAuthentication = (authToken) => {
  removeAuthentication();
  authInterceptor = apiClient.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `Bearer ${authToken}`;
      return Promise.resolve(config);
    },
    function(error) {
      return Promise.reject(error);
    }
  );
};

export const removeEntities = () => {
  if (entitiesInterceptor !== null) {
    apiClient.interceptors.request.eject(entitiesInterceptor);
    entitiesInterceptor = null;
  }
};

export const setEntities = (entities) => {
  removeEntities();
  entitiesInterceptor = apiClient.interceptors.request.use(
    (config) => {
      config.headers["X-EntityIds"] = JSON.stringify(entities);
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );
};

export default apiClient;
